import React from "react";
import ReactDOM from "react-dom/client";
import "../src/services/translations/i18n";
import "./index.scss";
import config from "config/main-config.json";
import { setGoogleAnalytics } from "helpers/index.helpers";
import App from "./App";
import { metaMap } from "constants/MetaTags";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

const city = config.stores[0].location.city.toLowerCase();

let meta = metaMap[city] || metaMap["default"];

let website_title;
let website_description;

if (meta.title) {
    website_title = meta.title;
    website_description = meta.description;
} else {
    website_title = meta.getTitle(config);
    website_description = meta.getDescription(config);
}

document.getElementById("website_title").innerText = website_title;
document
    .getElementById("website_description")
    .setAttribute("content", website_description);
const googleTagSrc = config.google_tags?.google_analytics?.src;
const googleTagScript = config.google_tags?.google_analytics?.script;
document.getElementById("google_analytics_src").src = googleTagSrc;
document.getElementById("canonical_link").setAttribute("href", window.location);
document.getElementById("google_analytics_script").innerText = googleTagScript;

if (config.google_tags.google_tag_manager) {
    document.getElementById("google_tag_manager_script").innerText =
        config.google_tags.google_tag_manager.script;
    document.getElementById("google_tag_manager_noscript").innerText =
        config.google_tags.google_tag_manager.no_script_iframe;
}

if (config.heusden_script) {
    document.getElementById("heusden_script").innerText = config.heusden_script;
}

if (config.atlanta_popup_script) {
    document.getElementById("signupScript").src = config.atlanta_popup_script;
}

if (config.showScrollbarClass) {
    document.body.classList.add("show-scrollbar");
}

if (config.meta_pixel_code) {
    document.getElementById("meta_pixel_code").innerText =
        config.meta_pixel_code.script;
    document.getElementById("meta_pixel_code_noscript").innerText =
        config.meta_pixel_code.noscript;
}

if (Array.isArray(config.google_tags?.google_analytics)) {
    setGoogleAnalytics(config);
}

if (config.google_tags?.google_site_verification) {
    const metaTag = document.createElement("meta");
    metaTag.name = "google-site-verification";
    metaTag.content = config.google_tags.google_site_verification;
    document.head.appendChild(metaTag);
}

if (config.umami?.src && config.umami?.website_id) {
    const umamiScript = document.createElement("script");
    umamiScript.defer = true;
    umamiScript.src = config.umami.src;
    umamiScript.setAttribute("data-website-id", config.umami.website_id);
    document.head.appendChild(umamiScript);
}

if (config.drip_account) {
    const dripScriptTag = document.getElementById("drip_script");

    if (dripScriptTag) {
        dripScriptTag.type = "text/javascript";
        dripScriptTag.innerHTML = `
        var _dcq = _dcq || [];
        var _dcs = _dcs || {};
        _dcs.account = '${config.drip_account}';
        (function() {
          var dc = document.createElement('script');
          dc.type = 'text/javascript';
          dc.async = true;
          dc.src = '${config.drip_src}';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(dc, s);
        })();
      `;
    }
}

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
